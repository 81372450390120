.page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info {
    display: flex;
    margin: 1em 0;
    justify-content: space-evenly;
    background-color: var(--window-background-color);
    border-radius: 1em;
    align-items: center;
    width: 500px;
}

.info h2 {
    margin: 0;
}

.startButton {
    margin-top: 1em;
    margin-bottom: 3em;
}


@media (min-width: 0px) {
    .canvas {
        width: 640px;
        height: 640px;
    }
}

@media (min-width: 1920px) {
    .canvas {
        width: 740px;
        height: 740px;
    }
}

@media (min-width: 2250px) {
    .canvas {
        width: 940px;
        height: 940px;
    }
}
