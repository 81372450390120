.page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.contents {
    display: flex;
    flex-direction: column;
    align-items: center;
}