@media (min-width: 0px) {
    .cell {
        width: 30px;
        height:30px;
    }
}

@media (min-width: 1250px) {
    .cell {
        width: 35px;
        height:35px;
    }
}

@media (min-width: 1700px) {
    .cell {
        width: 40px;
        height:40px;
    }
}

@media (min-width: 2000px) {
    .cell {
        width: 45px;
        height:45px;
    }
}

@media (min-width: 2500px) {
    .cell {
        width: 50px;
        height:50px;
    }
}