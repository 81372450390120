.options {
    margin-bottom: 3em;
}

.options button {
    margin: 1em;
}

.board {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head {
    margin: 1em 0;
    width: 350px;
    border-radius: 1em;
    display: flex;
    justify-content: space-between;
    background-color: var(--window-background-color);
    align-items: baseline;
}

.head h2 {
    margin: 0 1em;
}