.nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height:50px;
    margin: 0;
    padding: 0;
}

.header {
    background-color: var(--window-background-color);
    padding: 1em 0.5em;
}

.gameTitle {
    flex: 2;
    margin: 0;
    text-align: center;
}

.topRight {
    flex: 1;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
}

.home {
    flex: 1;
    background: none;
    font-size: smaller;
}

.home a {
    font-weight: bold;
    color: inherit;
    border: 2px var(--white-font-color) solid;
    border-radius: 1.5em;
    padding: 10px;
}

.home a:active {
    color: inherit;
    box-shadow: none;
}

.home:hover {
    background-color:rgba(240, 248, 255, 0);
}

#logout {
    color: inherit;
    background-color: #8b67ff;
    padding: 5px 10px;
    border-radius: 1.5em;
    transition: 0.25s ease-in-out;
}

#logout:hover {
    background-color: #b49efc;
}

#logout:active {
    transition: none;
    box-shadow:
        inset 2px 2px 1px black,
        inset 2px 3px 5px rgb(0 0 0 / 30%),
        inset -2px -3px 5px rgb(255 255 255 / 50%);
}

#profileLink {
    all: unset;
}

#profileLink:hover {
    cursor: pointer;
}
  
.profilePic {
    width: 35px;
    height: 35px;
    border-radius: 5em;
    object-fit: cover;
}

.nav h1 {
    margin: 0;
    font-size: 1.3em;
    text-align: center;
}

/* larger screens larger things */
@media (min-width: 750px) {
    .nav h1 {
        font-size: x-large;
    }

    .home {
        font-size: 1.1em;
    }

    .profilePic {
        width: 50px;
        height: 50px;
    }

    .topRight {
        gap: 0.5em;
    }

    .header {
        padding: 1em 1em;
    }
}

@media (min-width: 1100px) {
    .nav h1 {
        font-size: xx-large;
    }
}

@media (min-width: 2250px) {
    .nav h1 {
        font-size: larger;
    }
}
  