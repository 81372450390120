.page {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.head {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    background-color: var(--window-background-color);
    border-radius: 1em;
    width: fit-content;
    padding: 0 1em;
    margin: 1em 0;
}

.head h2 {
    margin: 0;
}

@media (min-width: 0px) {
    .canvas {
        width: 365px;
        height: 365px;
    }
}

@media (min-width: 750px) {
    .canvas {
        width: 700px;
        height: 700px;
    }
}

@media (min-width: 2250px) {
    .canvas {
        width: 900px;
        height: 900px;
    }
}
