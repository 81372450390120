table {
    border-collapse: collapse;
    border: 2px solid var(--white-font-color);
    font-family: sans-serif;
    font-size: 0.8rem;
    letter-spacing: 1px;
    background-color: #141313;
    counter-reset: rowNumber;
}

th,
td {
    border: 1px solid var(--white-font-color);
    padding: 1em 2em;
}

.mobileSpacing {
    padding: 0.5em 1em;
}

td img {
    width: 2em;
    height: 2em;
    border-radius: 5em;
    object-fit: cover;
}

tbody tr {
    text-align: left;
}


tbody tr:nth-child(odd) {
    background-color: #423996be;
}

tbody tr:nth-child(even) {
    background-color: #4239967a;
}

.cell {
    display: flex;
    align-items: center;
    justify-content: left;
    gap: 5px;
}

#showAll {
    all: unset;
    margin-top: 1em;
    cursor: pointer;
}

#showAll:hover {
    color: grey;
}

.tableContainer {
    width: 340px;
    background-color: var(--window-background-color);
    border-radius: 1em;
    margin: 2em;
    margin-bottom: 5em;
    display: flex;
    justify-content: center;
    padding: 0 1em 2em 1em ;
}

@media (min-width: 750px) {
    .tableContainer {
        width: 500px;
    }
}