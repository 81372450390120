.component {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2em;
}

.controls {
    font-size: 1em;
}

.summary, .controls {
    width: 340px;
    background-color: var(--window-background-color);
    border-radius: 1em;
    padding: 0 1em;
}

.summary h2, .controls h2 {
    text-align: center;
}

.summary li {
    padding-bottom: 1em;
}

.summary p {
    padding: 0 1em 0 1em;
}

.controls {
    width: fit-content; 
    margin: auto; 
    padding-left: 0; 
    list-style: none; 
}

.control {
    display: flex;
    gap: 10px;
}

.control span {
    display: flex;
    align-items: center;
}

@media (min-width: 750px) {
    .summary, .controls { 
        width: 500px;
    }

    .controls {
        width: fit-content; 
    }
}