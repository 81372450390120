.page {
    display: flex;
    justify-content: center;
}

.form, .deleteForm {
    width: 250px;
    height: 325px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 1em 1em 1em ;
    background-color: var(--window-background-color);
    border-radius: 1em;
    text-align: center;
    gap: 5px;
}

.deleteForm {
    justify-content: center;
    padding: 0 1em;
}

.deleteOptions {
    column-gap: 5px;
}

.profilePic {
    width: 150px;
    height: 150px;
    object-fit: cover;
    border-radius: 5em;
    margin-top: 1em;
}

.button {
    margin: 0.5em;
}

#username {
    margin-top: 0.5em;
}

@media (min-width: 2250px) {
    .form {
        width: 275px;
        height: 358px;
        font-size: larger;
    }
}