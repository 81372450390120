body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background: radial-gradient(rgba(136, 53, 212, 0.753), black); */
  /* background: linear-gradient(135deg, #423996, #423996);  */
  background-color: var(--main-background-color);
  color: var(--white-font-color);
  /* font-family:'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif; */
  display: flex;
  flex-direction: column;
  height: 100%;
}

h1 {
  margin: 0;
  margin-bottom: 1em;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

button {
  padding: 8px 15px;
  width:fit-content;
  border-radius: 1.5em;
  background-color: #8b67ff;
  color: white;
  border: none;
  cursor: pointer;
  text-decoration: none;
  transition: 0.25s ease-in-out;
}

button:hover {
  background-color: #b49efc;
}

a:active, button:active {
  transition: none;
  box-shadow:
    inset 2px 2px 1px black,
    inset 2px 3px 5px rgb(0 0 0 / 30%),
    inset -2px -3px 5px rgb(255 255 255 / 50%);
}


main {
  align-content: center;
}
