.page {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.5);
    top: 0;
    left: 0;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: none;
    justify-content: center;
    align-items:center;
}

.signIn {
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 300px;
    /* height: 400px; */
    border-radius: 2em;
    background-color: var(--window-background-color);
    padding-bottom: 2em;
    padding-top: 1em;
}

.xContainer {
    text-align: right;
    margin-right: 1.5em;
}

.x {
    all:unset;
    border-radius: 5em;
    width: 1.25em;
    text-align: center;
}

.x:hover {
    background-color: unset;
    cursor: pointer;
    color: #c5bfbf;
}

.x:active {
    box-shadow: none;
}

.formContainer {
    display: flex;
}

.signUp {
    margin-top: 1.5em;
}

.form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3px;
}

.form button {
    margin-top: 1em;
}

.googleButton {
    display: flex;
    justify-content: center;
}