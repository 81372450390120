.page {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.head {
    width: fit-content;
    text-align: center;
    padding: 0 1em;
    margin: 1em 0;
    background-color: var(--window-background-color);
    border-radius: 1em;
}

.head h2 {
    margin: 0;
}

.button {
    display: flex;
    margin-top: 1em;
    margin-bottom: 3em;
}


@media (min-width: 2250px) {
    .canvas {
        width: 700px;
        height: 933px;
    }
}
