.table_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.gameStats {
    width: fit-content;
    gap: 5em;
    padding: 0 1em;
    margin: 1em 0;
    background-color: var(--window-background-color);
    border-radius: 1em;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-size: small;
}

.gameStats h2 {
    margin: 0;
}

.button_container {
    position: relative;
    padding: 1em;
    width: 200px;
    gap: 10px;
    display: flex;
    justify-content: center;
}

@media (min-width: 750px) {
    .gameStats {
        font-size: medium;
    }
}