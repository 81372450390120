.carouselContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 3em 0;
}

.carouselContainer h1 {
    margin-bottom: 0;
}

.carousel {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 290px;
    position: relative;
    overflow: hidden;
}

.middleLink {
    all:unset;
    padding: 0%;
    background: none ;
    cursor: pointer;
    width: 240px;
    height: 240px;
    object-fit: cover;
    border-radius: 3em;
    position: absolute;
}

.artCover  {
    border: 3px solid var(--white-font-color);
    width: 240px;
    height: 240px;
    object-fit: cover;
    border-radius: 3em;
    position: absolute;
    transition: transform 0.35s ease-in-out, opacity 0.35s ease-in-out;
}

.middle {
    transform: translateX(0);
    opacity: 1;
    z-index: 2;
}

.left {
    transform: translate(-100%) scale(0.8);
}

.right {
    transform: translate(100%) scale(0.8);
}

.left, .right {
    opacity: 0.7;
    z-index: 1;
}

.leftleft {
    transform: translate(-60%) scale(0.5);
}

.rightright {
    transform: translate(60%) scale(0.5);
}

.leftleft, .rightright {
    opacity: 0;
    z-index: 0;
    cursor: default;

}

.buttons {
    display: flex;
    gap: 2em;
}

/* Arrow buttons */
.arrow, .random {
    all: unset;
    background: none;
    border: none;
    font-size: 2em;
    color: var(--white-font-color);
    cursor: pointer;
}

.arrow:hover, .random:hover {
    background-color: inherit;
}

.arrow:active, .random:active {
    box-shadow: none;
}

.gameDetails {
    text-align: center;
    margin-top: 20px;
}

.playBtn {
    padding: 10px 20px;
    margin-top: 1em;
    border-radius: 1.5em;
    background-color: #8b67ff;
    color: inherit;
    font-size: 1.1em;
    cursor: pointer;
    transition: 0.25s ease-in-out;
}

.playBtn:hover {
    background-color: #b49efc;
}

.description {
    margin-bottom: 10px;
    font-size: 0.9em;
    color: #faf1e6;
}

/* tablet */
@media (min-width: 750px) {
    .artCover, .middleLink {
        width: 520px;
        height: 510px;
    }

    .carouselContainer {
        padding: 0;
    }

    .carousel {
        height: 570px;
    }

    .description {
        font-size: 1.1em;
    }
}

/* 23ish inch */
@media (min-width: 1100px) {
    .artCover, .middleLink {
        width: 400px;
        height: 400px;
    }

    .carousel {
        height: 500px;
    }
}

/* big boys */
@media (min-width: 2250px) {
    .artCover, .middleLink {
        width: 500px;
        height: 500px;
    }

    .carousel {
        height: 600px;
    }
}