.window {
    border: 1px solid var(--white-font-color);
    border-radius: 1em;
    background-color: var(--window-background-color);
    position: absolute;
    top: 50%;
    bottom:50%;
    transform: translate(0%, -50%);
    width: 200px;
    padding-bottom: 1em;
    height: fit-content;
    text-align: center;
}