.page {
    display: flex;
    flex-direction: column;
    align-items: center; 
}

.head {
    display: flex;
    justify-content: space-around;
    align-items: baseline;
    background-color: var(--window-background-color);
    border-radius: 1em;
    width: 500px;
    margin: 1em 0;
}

.head h2 {
    margin: 0;
}

.buttons {
    margin-top: 1em;
    margin-bottom: 3em;
}

.canvas {
    width: 770px;
    height: 715px;
}

@media (min-width: 1920px) {
    .canvas {
        width: 840px;
        height: 780px;
    }
}

@media (min-width: 2250px) {
    .canvas {
        width: 910px;
        height: 845px;
    }
}